@import 'styles/utils/index';

// .gdb-product-pricing-hero-1 .gdb-product-pricing-hero-2 .gdb-product-pricing-hero-3
.gdb-product-pricing-hero {
  &-1 {
    margin: 0 auto rem(15px);
  }

  &-2 {
    margin: rem(25px) auto rem(30px);
  }
}

.gdb-product-pricing-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: rem(50px);
  margin-left: rem(16px);
  margin-right: rem(16px);

  > *:not(:last-child) {
    border-right: 2px solid $grey-100;
  }

  button {
    @include mq($until: small-desktop) {
      font-size: rem(15px) !important;
    }
  }
}
